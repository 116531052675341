import React, {useEffect, useState} from "react";
import BsIcon from "../../bsIcon";
import {Button, Collapse, Form, InputGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function GroupsFilter({groups, setFilteredGroups, showMessage, noBackButton}) {
    const { t } = useTranslation();
    const navigate = noBackButton ? null : useNavigate();

    const [open, setOpen] = useState(false);

    const uniqueAddresses = [...new Set(groups?.map(group => group.address))];
    const uniqueTeachers = [...new Set(groups?.map(group => group.teachers_name))];

    const [addressOptions, setAddressOptions] = useState(uniqueAddresses)
    const [teacherOptions, setTeacherOptions] = useState(uniqueTeachers)

    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState('');

    useEffect(() => {
        setAddressOptions([...new Set(groups?.map(group => group.address))])
        setTeacherOptions([...new Set(groups?.map(group => group.teachers_name))])
    }, [groups]);

    useEffect(() => {
        const filteredGroups = groups?.filter(group => {
            const addressMatch = !selectedAddress || group.address === selectedAddress;
            const teacherMatch = !selectedTeacher || group.teachers_name === selectedTeacher;
            return addressMatch && teacherMatch;
        });
        setFilteredGroups(filteredGroups);
    }, [selectedAddress, selectedTeacher, groups, setFilteredGroups]);

    const onSelect = (e) => {
        const { id, value } = e.target

        if (id === "address" && value !== selectedAddress) {
            const filteredTeachers = value === '' ? uniqueTeachers : [...new Set(groups?.filter(group => group.address === value).map(group => group.teachers_name))];

            setTeacherOptions(filteredTeachers)
            setSelectedAddress(value);
        }

        if (id === "teacher" && value !== selectedTeacher) {
            const filteredAddresses = value === '' ? uniqueAddresses : [...new Set(groups?.filter(group => group.teachers_name === value).map(group => group.address))];

            setAddressOptions(filteredAddresses)
            setSelectedTeacher(value);
        }
    };

    return <>
        {showMessage && <div className="alert alert-success w-100" role="alert">
            {t('successful_join')}
            <a href="/events">{t('click_here')}</a>
            {t('to_apply')}
        </div>}


            <div className={`d-flex flex-row justify-content-${noBackButton ? "end" : "between"} align-items-center m-1 mb-4`}>
                {!noBackButton && <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1 ps-2 text-primary"
                        onClick={() => navigate(-1)} /> }
                {(uniqueAddresses.length > 1 || uniqueTeachers.length > 1) &&
                    <Button size="sm" className="border-primary h-50 bg-white text-black" variant="white" onClick={() => setOpen(!open)}
                            aria-controls="filterForm"
                            aria-expanded={open}>
                        <BsIcon classNames="me-2" icon="funnel-fill"/>
                        {t('filters')}
                    </Button>
                }
            </div>


        <Collapse in={open}>
            <Form id="filterForm" className="justify-content-end mb-4">
                {uniqueAddresses.length > 1 &&
                    <InputGroup className="justify-content-end">
                            <Form.Label className="p-2 mb-0 me-4">{t('address')}:</Form.Label>
                            <Form.Select size="sm" id="address" className="bg-white flex-grow-0 p-2 rounded form-select-sm"
                                         style={{"width": "65%"}}
                                         value={selectedAddress || ''}
                                         onChange={onSelect} >
                                <option value="">{t('all')}</option>
                                {addressOptions.map((option, index) => <option value={option}
                                                                                key={index}>{option?.split(' - ')[0]}</option>)}
                            </Form.Select>
                    </InputGroup>
                }
                {uniqueTeachers.length > 1 &&
                    <InputGroup className="justify-content-end">
                        <Form.Label className="p-2 mt-2 mb-0 me-4">{t('teachers')}:</Form.Label>
                        <Form.Select size="sm" id="teacher" className="mt-2 bg-white flex-grow-0 p-2 rounded"
                                     style={{"width": "65%"}}
                                     value={selectedTeacher || ''}
                                     onChange={onSelect} >
                            <option value="">{t('all')}</option>
                            {teacherOptions.map((option, index) => <option value={option} key={index}>{option}</option>)}
                        </Form.Select>
                    </InputGroup>
                }
            </Form>
        </Collapse>
    </>
}

export default GroupsFilter;