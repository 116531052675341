import React from "react";

// Refs don't work with functional components
class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const checked = event.target.checked
        this.setState({checked: checked})
        this.props.handleChange(checked)
    }

    render() {
        return <form className='form-check form-switch'>
                <input role="switch"
                       type="checkbox"
                       className="form-check-input toggle_async_react"
                       style={this.props.style}
                       disabled={this.props.disabled}
                       checked={this.state.checked}
                       onChange={this.handleChange}/>
                </form>
    }
}

export default Switch;

