import React, {useEffect} from "react";
import {sortApplicationsByNameAndPaid} from "../../helpers";
import {useTranslation} from "react-i18next";
import PaidBadge from "../badges/paidBadge";

function ApplicationsTable({applications, actionColumn, fetchApplications}) {
    const { t } = useTranslation();

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchApplications();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    return <div className="table-responsive">
        <table className="table">
            <tbody>
                {sortApplicationsByNameAndPaid(applications).map((application) => {
                    return <tr key={application.id} className="border-bottom">
                        <td className="ps-0 text-start">
                            <span className="fs-6">{application.user.full_name}</span>
                            {application.paid && <PaidBadge/>}
                            <br className="d-sm-none"/>
                            <span className="d-sm-none small text-secondary">{application.user.email}</span>
                            {application.role && <>
                                <br className="d-sm-none"/>
                                <span className="d-sm-none small text-secondary d-md-block">{t(`admin.${application.role}`)}</span>
                            </>}
                        </td>
                        <td className="d-none d-md-inline p-0">
                            <span className="d-md-block">{application.user.email}</span>
                        </td>
                        <td className="pe-0 text-end">
                            {actionColumn(application)}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default ApplicationsTable;
