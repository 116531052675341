import React from "react";
import {logout} from "../../helpers";
import {useTranslation} from "react-i18next";

function LogoutLink() {
    const {t} = useTranslation();

    const onClick = e => {
        e.preventDefault();
        logout();
    }

    return <a href="#" className="nav-link" onClick={onClick}>
        {t('logout')}
    </a>
}

export default LogoutLink;