import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import WorkshopCard from "../cards/workshopCard";

function WorkshopApplyFinished(props) {
    const { t } = useTranslation();

    useEffect(() => {
        props.setFlash({'notice': t('successfully_applied', {email: props.formData.email || props.currentUser.email})});
        props.setHideKeys(true);
    }, []);

    const Events = () => {
        const filteredEvents = {};

        Object.entries(props.workshop?.ws_events || {}).forEach(([date, events]) => {
            const fEvents = events.filter(event => props.formData.ws_event_ids.includes(event.id));

            if (fEvents.length > 0) {
                filteredEvents[date] = fEvents;
            }
        });

        return filteredEvents
    }

    return <WorkshopCard key={props.workshop.id} workshop={props.workshop} renderEvents={Events()} />
}

export default WorkshopApplyFinished;