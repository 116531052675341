import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import ExcelExportButton from "../../components/excelExportButton";
import {performAjaxRequest} from "../../helpers";
import WsApplicationModal from "../../components/modals/wsApplicationModal";
import BsIcon from "../../components/bsIcon";
import {useNavigate} from "react-router-dom";
import ApplicationsTable from "../../components/tables/applicationsTable";


function AdminWsApplicationsPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ws, setWs] = useState(null);
    const [wsApplications, setWsApplications] = useState(null);
    const [applicationId, setApplicationId] = useState(null);

    const exportHeaders = ["name", "email", "role", "ws_applications", "paid_ws_applications", "all_paid"]
        .concat(ws?.custom_fields && ws.custom_fields.map(field => field.label) || []);
    const exportData = wsApplications?.map(app => ({
        "name": app.user.full_name,
        "email": app.user.email,
        "role": t(`admin.${app.role}`),
        "ws_applications": app.ws_events && Object.entries(app.ws_events)
            .map(([_, events]) => events.map(event => event.name))
            .join(', '),
        "paid_ws_applications": app.ws_events && Object.entries(app.ws_events)
            .flatMap(([_, events]) => events.filter(event => event.paid).map(event => event.name))
            .join(', '),
        "all_paid": t(app.paid),
        ...app.answers
    }))

    const fetchApplications = () => {
        performAjaxRequest(
            'GET',
            `/admin/workshops/${id}/ws_applications.json`,
            null,
            {success:(response) => {setWsApplications(response);}}
        )
    };

    useEffect(() => {
        fetchApplications();

        performAjaxRequest(
            'GET',
            `/workshops/${id}.json`,
            null,
            {success:(response) => {setWs(response);}}
        )
    }, []);

    const modalButton = application => (
        <Button variant="white" type="submit" data-bs-target="#wsApplicationModal" data-bs-toggle="modal"
                onClick={() => {setApplicationId(application.id)}} >
            { t('admin.modify') }
        </Button>
    )

    return <div>
            <WsApplicationModal ws={ws} applicationId={applicationId} wsApplications={wsApplications} fetchApplications={fetchApplications} />

            <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1 text-primary" onClick={() => navigate(-1)}/>

            <div className="d-flex flex-column mb-3">
                <h4>{ws?.name}</h4>
                <span className="text-secondary">{ws?.datetime}</span>
            </div>

            <ApplicationsTable applications={wsApplications} actionColumn={modalButton} fetchApplications={fetchApplications} />

            {wsApplications?.length > 0 && <>
                <span>{t('admin.download')} </span>
                <ExcelExportButton headers={exportHeaders} data={exportData} title={ws?.name}/>
            </>}
    </div>
}

export default AdminWsApplicationsPage;
