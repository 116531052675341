import React from "react";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Password from "./fields/password";
import {useParams} from "react-router";
import DataPolicy from "./fields/dataPolicy";
import Submit from "./fields/submit";
import Back from "./fields/back";
import BasicForm from "./basicForm";
import {performAjaxRequest} from "../../helpers";

function WorkshopApplyFourthStep(props) {
    const { id } = useParams();
    const { t } = useTranslation();

    const onSubmit = (e) => {
        if (props.formData.also_register === "1") {
            props.formData.workshop_id = id
            performAjaxRequest(
                'POST',
                '/users.json',
                { user: props.formData },
                {props: props}
            )
        } else {
            performAjaxRequest(
                'POST',
                `/workshops/${id}/ws_applications.json`,
                { applicant: props.formData },
                {success: () => {props.setPage(props.page + 2)}, props: props}
            )
        }
    }

    return <BasicForm onSubmit={onSubmit}>
        <Form.Group className="my-3">
            <Form.Label className="my-3">{ t('join_us', {company: props.company_attributes.name}) }</Form.Label>

            <FormCheck>
                <FormCheck.Input onChange={props.checkboxInputChange}
                            checked={props.formData.also_register === "1"}
                            className="mb-3 border border-secondary"
                            id="also_register" type="checkbox" name="also_register" value="1"/>
                <FormCheck.Label className="text-light" htmlFor="also_register" style={{width: "100%"}}>{t('also_register')}</FormCheck.Label>
            </FormCheck>

            {props.formData.also_register === "1" &&
                <Password formData={props.formData} handleInputChange={props.handleInputChange} /> }
        </Form.Group>

        <DataPolicy dataPolicyPage={props.numSteps} {...props} />
        <Submit text={t('finish')} />
        <Back previousPage={props.page - 1} {...props} />
    </BasicForm>
}

export default WorkshopApplyFourthStep;