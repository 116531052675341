import React, {useState, useEffect} from "react";
import WorkshopCard from "../components/cards/workshopCard";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../helpers";

function WorkshopsPage() {
    const [workshops, setWorkshops] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        performAjaxRequest(
            'GET',
            '/workshops.json',
            null,
            {success: (response) => {setWorkshops(response)}}
        )
    }, []);

    if (!workshops) {
        return <p>{t('loading')}</p>
    } else if (workshops.length === 0) {
        return <p>{t('no_workshops')}</p>
    } else {
        return <div style={{marginBottom: "90px"}}>
            {workshops.map((ws) => {
                return <WorkshopCard key={ws.id} wrapped={true} workshop={ws} renderEvents={ws.appliedEvents} />
            })}
        </div>
    }
}

export default WorkshopsPage;