import React from "react";
import { useTranslation } from 'react-i18next';
import Password from "../components/forms/fields/password";
import {useParams} from "react-router";
import Submit from "../components/forms/fields/submit";
import {useNavigate} from "react-router-dom";
import BasicForm from "../components/forms/basicForm";
import {performAjaxRequest} from "../helpers";

function PasswordEditPage(props) {
    const navigate = useNavigate();
    const { token } = useParams();
    const { t } = useTranslation();

    const successCallback = () => {
        navigate('/events');
        props.setFlash({'notice': t('flash.notices.pwd_reset_successful')});
        props.setHideKeys(true);
    }

    const onSubmit = (formData) => {
        performAjaxRequest(
            "PUT",
            `/password_resets/${token}.json`,
            formData,
            {success: successCallback, props: props})
    }

    return <BasicForm keys={["password"]}
                      title={ t('password_reset') }
                      onSubmit={onSubmit} >
            <Password autoFocus={true} />
            <Submit text={ t('password_reset') } />
    </BasicForm>
}

export default PasswordEditPage;