import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BasicForm from "./basicForm";
import Level from "./fields/level";
import Role from "./fields/role";
import Submit from "./fields/submit";
import Back from "./fields/back";

function WorkshopApplySecondStep(props) {
    const { t } = useTranslation();

    useEffect( () => {
         if (props.workshop?.full_for_followers) {
             props.setFormData({
                 ...props.formData,
                 role: "leader"
             });
         }

         if (props.workshop?.full_for_leaders) {
             props.setFormData({
                 ...props.formData,
                 role: "follower"
             });
         }
     }, [props.workshop])

    return <BasicForm onSubmit={() =>  props.setPage(props.page + 1)}>
        <Level {...props} />
        <Role {...props} />
        <Submit text={t('next')} />

        {props.page === 0 ? <div className="small mt-3 text-center"><Link to="/workshops">{ t('back') }</Link></div>
                          : <Back previousPage={props.page - 1} {...props} />}
    </BasicForm>
}

export default WorkshopApplySecondStep;