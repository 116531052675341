import React from 'react';
import * as ExcelJS from 'exceljs';
import {useTranslation} from "react-i18next";

const ExcelExportButton = (props) => {
    const { t } = useTranslation();

    const handleExport = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        worksheet.addRow(props.headers.map(header => {
            const translationKey = `admin.export_headers.${header}`;
            const translation = t(translationKey);
            return translation === translationKey ? header : translation;
        }))
        props.data.forEach(row => {
            const rowData = props.headers.map(header => row[header]);
            worksheet.addRow(rowData);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
            const sanitizedTitle = props.title.replace(/[^a-zA-Z0-9]/g, '_');// Generate a unique timestamp and remove special characters
            a.href = url;
            a.download = `${sanitizedTitle}_${timestamp}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    };

    return (
        <a href="#" onClick={handleExport}>
            XLSX
        </a>
    );
};

export default ExcelExportButton;