import React, {forwardRef} from "react";
import {useTranslation} from 'react-i18next';
import Modal from "./modal";
import TextPrimary from "../textPrimary";
import {Button} from "react-bootstrap";
import {performAjaxRequest} from "../../helpers";
import {useParams} from "react-router";

const ApplicationModal = forwardRef(({user, group, fetchApplications, ...props}, ref) => {
        const { t } = useTranslation();
        const { id } = useParams();

        const onClick = () => {
            $('button.btn-close').click()
            performAjaxRequest(
                'POST',
                `/admin/events/${id}/applications.json`,
                {application: {user_id: user?.id}},
                {success: fetchApplications, props: props}
            )
        }

        return <Modal ref={ref} id="applicationModal" title={t('admin.add_application')}>
            <TextPrimary br={true} text={user?.full_name}/>

            {!user?.group_ids.includes(group?.id) &&
                <div className="alert alert-warning mt-4 mb-0" role="alert">
                    {t('admin.not_yet_a_member', {user: user?.full_name, group: group?.name})}
                </div>
            }

            <div className="text-center d-grid gap-2 mt-5">
                <Button variant="primary" type="submit" onClick={onClick}> {t('admin.add_application')} </Button>
            </div>
        </Modal>
    }
);

export default ApplicationModal;
