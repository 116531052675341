import React from "react";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Preferences(props) {
    const { t } = useTranslation();

    return <Form.Group className="my-3">
        <FormCheck>
            <FormCheck.Input onChange={props.preferencesCheckboxInputChange}
                             checked={props.formData.preferences?.event_notification === "1"}
                             className="mb-3 border border-secondary"
                             id="event_notification" type="checkbox" name="event_notification" value="1"/>
            <FormCheck.Label className="text-light" htmlFor="event_notification" style={{width: "100%"}}>{t('event_notifications')}</FormCheck.Label>
        </FormCheck>
    </Form.Group>
}

export default Preferences;