import React, { useRef, useState } from "react";

import Switch from "../switches/switch";
import ContainerFluid from "../containers/container_fluid";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";

function NotificationSettingCard({ checked, user_id, name, setPreferences }) {
    const [isChecked, setIsChecked] = useState(checked);
    const switchRef = useRef();
    const { t } = useTranslation();

    const handleSwitchChange = (newChecked) => {
        setIsChecked(newChecked)
        switchRef.current.setState({checked: newChecked})
    }

    const handleClick = () => {
        const newChecked = !isChecked;
        handleSwitchChange(newChecked);

        performAjaxRequest(
            "PUT",
            `/users/${user_id}`,
            { user: { preferences: { [name]: newChecked } } },
            {success: (response) => setPreferences(response.preferences),
                     error: () => {handleSwitchChange(!newChecked)}}
        )
    };

    return (
        <ContainerFluid shadow={true} className="justify-content-between bg-dark my-2 px-3 py-2" onClick={handleClick} >
            <span>{t(name)}</span>

            <Switch ref={switchRef}
                    style={{ transform: "scale(1.3)" }}
                    disabled={false}
                    checked={isChecked}
                    handleChange={() => {}} />
        </ContainerFluid>
    );
}

export default NotificationSettingCard;
