import React from "react";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function DataPolicyCheckbox(props) {
    const { t } = useTranslation();

    const dataPolicyPage = <span>
        { t('i_accept') }
        <Link onClick={(e) => {
            e.preventDefault()
            props.setPage(props.dataPolicyPage)
        }} >{ t('data_policy') }</Link>
    </span>

    return <Form.Group className="my-3">
        <FormCheck isInvalid={props.formData.data_policy === ''}>
            <FormCheck.Input onChange={props.checkboxInputChange}
                             checked={props.formData.data_policy === "1"}
                             className="border border-secondary"
                             id="data_policy" type="checkbox" name="data_policy" value="1" required/>
            <FormCheck.Label className="text-light" htmlFor="data_policy" style={{width: "100%"}}>{dataPolicyPage}</FormCheck.Label>
            <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>
        </FormCheck>
    </Form.Group>
}

export default DataPolicyCheckbox;