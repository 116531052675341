import {Link} from "react-router-dom";
import BsIcon from "../bsIcon";
import React from "react";
import {useTranslation} from "react-i18next";

const FooterItem = ({ to, icon, label, active }) => {
    const {t} = useTranslation();

    return <div className="d-flex flex-column text-center">
        <Link to={to}>
            <BsIcon icon={icon}
                    classNames={`fs-2 footer_item text-secondary ${active || location.pathname === to ? 'bg-black p-2 mb-6 rounded' : ''}`}/>
        </Link>
        <span className="footer_text footer_item text-secondary mt-1"
              style={{fontSize: "0.75em"}}>{label ? label : t(to.substring(1))}</span>
    </div>
};

export default FooterItem;