import React from "react";
import {useTranslation} from "react-i18next";
import BasicForm from "./basicForm";
import Submit from "./fields/submit";
import Back from "./fields/back";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import {performAjaxRequest} from "../../helpers";
import {useParams} from "react-router";

function WorkshopApplyCustomStep(props) {
    const { t } = useTranslation();
    const { id } = useParams();

    const nextStep = () => {props.setPage(props.page + 1)}

    const handleCustomInputChange = e => {
        const { name, value } = e.target;
        props.setFormData({
            ...props.formData,
            "answers": {...props.formData.answers, [name]: value }
        });
    }

    const checkboxCustomInputChange = e => {
        const { name, checked } = e.target;
        const id = e.target.id

        let updatedCustomInputValues = props.formData["answers"][name] || [];

        if (checked) {
            updatedCustomInputValues.push(id)
        } else {
            updatedCustomInputValues.splice(updatedCustomInputValues.indexOf(id), 1);
        }

        props.setFormData({
            ...props.formData,
            "answers": {...props.formData.answers, [name]: updatedCustomInputValues }
        });
    }

    const onSubmit = () => {
        if (props.currentUser) {
            performAjaxRequest(
                'POST',
                `/workshops/${id}/ws_applications.json`,
                { applicant: props.formData },
                {success: nextStep, props: props}
            )
        } else {
            nextStep()
        }
    }

    const renderCustomField = (field, index) => {
        switch (field.field_type) {
            case 'text':
                return <Form.Group key={field.label} className="mb-3">
                    <Form.Label>{field.label}</Form.Label>
                    <Form.Control
                        onChange={handleCustomInputChange}
                        value={props.formData.answers[field.label] || ""}
                        type={field.field_type}
                        name={field.label}
                        size='sm'
                        className="bg-light text-dark"
                        autoFocus={index === 0}
                        required={field.required || false}
                    />
                    <Form.Control.Feedback type="invalid"> {t('errors.messages.blank')}</Form.Control.Feedback>
                </Form.Group>
            case 'radio':
                return <Form.Group  key={field.label} className="mb-3">
                    <Form.Label className="mb-2">{field.label}</Form.Label>

                    { field.values.split(",").map((value, index) => (
                        <FormCheck>
                            <FormCheck.Input onChange={handleCustomInputChange}
                                             className="mb-2 border border-secondary"
                                             type='radio'
                                             id={value.trim()}
                                             name={field.label}
                                             value={value.trim()}
                                             required={field.required} />
                            <FormCheck.Label className="text-light" htmlFor={value.trim()} style={{width: "100%"}}>{value.trim()}</FormCheck.Label>
                            {index === field.values.split(",").length - 1  && <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>}
                        </FormCheck>
                    ))}
                </Form.Group>;
            case 'checkbox':
                return <Form.Group  key={field.label} className="mb-3">
                    <Form.Label className="mb-2">{field.label}</Form.Label>

                    { field.values.split(",").map((value, index) => (
                        <FormCheck>
                            <FormCheck.Input onChange={checkboxCustomInputChange}
                                             className="mb-2 border border-secondary"
                                             type='checkbox'
                                             id={value.trim()}
                                             name={field.label}
                                             value="1" />
                            <FormCheck.Label className="text-light" htmlFor={value.trim()} style={{width: "100%"}}>{value.trim()}</FormCheck.Label>
                         </FormCheck>)
                    )}
                </Form.Group>
        }
    }

    return <BasicForm onSubmit={onSubmit}>
        {props.workshop.custom_fields.map((field, index) => {
            return renderCustomField(field, index)
        })}

        <Submit text={ props.currentUser ? t('finish') : t('next')  } />
        <Back previousPage={props.page - 1} {...props} />
    </BasicForm>
}

export default WorkshopApplyCustomStep;
