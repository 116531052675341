import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TextSmall from "../textSmall";

function AdminWsEventCard({event}) {
    const { t } = useTranslation();

    return <div className="rounded-3 d-flex align-items-center my-2 px-3 py-2 justify-content-between bg-light col-md-4">
        <div className="d-flex flex-column">
            <Link to={`/admin/ws_events/${event.id}/ws_applications_events`}>{event.name}</Link>
            <TextSmall text={`${event.start_time} - ${event.end_time}`} />
            <TextSmall text={t(event.level)} />
        </div>

        <div className="d-flex">
            <div className="d-flex flex-column text-center mx-1">
                <TextSmall noSecondary={true} text={t('admin.followers')} />
                <TextSmall noSecondary={true} text={`${event.ws_applications.filter(application => application.role === 'follower').length}`} />
            </div>
            <div className="d-flex flex-column text-center mx-1">
                <TextSmall noSecondary={true} text={t('admin.leaders')} />
                <TextSmall noSecondary={true} text={`${event.ws_applications.filter(application => application.role === 'leader').length}`} />
            </div>
        </div>
    </div>
}

export default AdminWsEventCard;
