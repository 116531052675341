import React from "react";

function ContainerFluid(props) {
        return (
            <div className={`container-fluid rounded-3 align-items-center ${!props.noFlex && 'd-flex' } ${props.shadow && 'shadow-sm' } ${props.className}`}
                 role={props.onClick ? "button" : null}
                 onClick={props.onClick} >
                    {React.Children.map(props.children, (child) =>
                        child && (props.dontWrap ? React.cloneElement(child) : (
                            <div className="d-flex" key={child?.key}>
                                    {React.cloneElement(child)}
                            </div>)
                        )
                    )}
            </div>
        );
}

export default ContainerFluid;
