import React from "react";
import { useTranslation } from 'react-i18next';
import {FormCheck} from "react-bootstrap";
import BodyWrapper from "../../components/containers/bodyWrapper";
import ContainerFluid from "../../components/containers/container_fluid";
import {performAjaxRequest} from "../../helpers";
import TextPrimary from "../../components/textPrimary";

function PersonalPage(props) {
    const { t } = useTranslation();

    const handleJokerChange = event => {
        props.setJoker(event.target.checked)

        performAjaxRequest(
            'PATCH',
            `/users/${props.currentUser.id}`,
            {user: {joker: `${event.target.checked}`}},
            {error: () => { props.setJoker(!event.target.checked) }}
        )
    }

    return <BodyWrapper>
        <ContainerFluid shadow={true} className="bg-dark px-3 p-4" noFlex={true} dontWrap={true}>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                    <TextPrimary text={t('name')} />
                    <TextPrimary text={t('profile_gender')} />
                    <TextPrimary text={t('email')} />
                </div>
                <div className="d-flex flex-column ms-2">
                    <span>{props.currentUser.full_name}</span>
                    <span>{t(props.currentUser.gender)}</span>
                    <span>{props.currentUser.email}</span>
                </div>
            </div>

            <div className="d-flex flex-row mt-3">
                <FormCheck>
                    <FormCheck.Input onChange={handleJokerChange}
                                     className="mb-2 border border-secondary"
                                     type="checkbox"
                                     id="joker" name="joker"
                                     value="1"
                                     checked={props.joker}/>
                    <FormCheck.Label htmlFor="joker" style={{width: "100%"}}> {t('joker')} </FormCheck.Label>
                </FormCheck>
            </div>

            <div className="d-flex flex-row justify-content-end mt-4">
                <a className="text-primary small" data-confirm={t('confirm_delete_profile')} rel="nofollow" data-method="delete"
                   href={`/users/${props.currentUser.id}`}>{t('delete_profile')}</a>
            </div>
        </ContainerFluid>
    </BodyWrapper>
}

export default PersonalPage;