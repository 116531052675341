import React, {useEffect, useMemo, useState} from "react";
import {Button, FormCheck} from "react-bootstrap";
import BsIcon from "../bsIcon";
import Modal from "./modal";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";
import PaidBadge from "../badges/paidBadge";
import TextSmall from "../textSmall";
import TextPrimary from "../textPrimary";
import Form from "react-bootstrap/Form";

function WsApplicationModal({ws, wsApplications, applicationId, fetchApplications}) {
    const {t} = useTranslation();
    const [wsEventIds, setWsEventIds] = useState([]);


    const wsEvents = useMemo(() => {
        return ws?.ws_events && Object.entries(ws?.ws_events).flatMap(([date, events]) =>
            events.map(event => event)
        ) || [];
    }, [ws]);
    const multiEventWs = wsEvents?.length > 1

    const currentApplication = wsApplications?.find(application => application.id === applicationId)
    const currentWsEvents = useMemo(() => {
        return currentApplication?.ws_events && Object.entries(currentApplication.ws_events).flatMap(([date, events]) =>
            events.map(event => event)
        ) || [];
    }, [currentApplication]);

    useEffect(() => {
        setWsEventIds(currentWsEvents.map(event => event.id));
    }, [currentWsEvents]);

    const findCurrentWsEvent = eventID => currentWsEvents.find(event => event.id === eventID)

    const onChange = e => {
        const { id, checked } = e.target;
        if (checked) {
            setWsEventIds(prevIds => [...prevIds, +id]);
        } else {
            setWsEventIds(prevIds => prevIds.filter(existingId => existingId !== +id));
        }
    }

    const onPaidClick = () => {
        if(wsEventIds.length === 0) {
            alert(t('admin.ws_applications_events_must_present'));
            return
        }
        $('button.btn-close').click()
        performAjaxRequest(
            'PUT',
            `/admin/workshops/${ws?.id}/ws_applications/${applicationId}`,
            {ws_application: {paid: true, ws_event_ids: wsEventIds}},
            {
                success: () => {
                    fetchApplications();
                },
                error: () => {
                    alert(t('admin.ws_subscription_creation_failed'));
                }
            }
        )
    }

    const onModifyClick = () => {
        if(wsEventIds.length === 0) {
            alert(t('admin.ws_applications_events_must_present'));
            return
        }
        $('button.btn-close').click()
        performAjaxRequest(
            'PUT',
            `/admin/workshops/${ws?.id}/ws_applications/${applicationId}`,
            {ws_application: {ws_event_ids: wsEventIds}},
            {success: () => {fetchApplications();},
                error: () => {alert(t('admin.ws_application_modification_failed'));}
            }
        )
    }

    return <Modal id="wsApplicationModal" title={t('admin.add_ws_subscription')}>
        <TextPrimary br={true} text={currentApplication?.user?.full_name}/>

        {ws?.ws_events && Object.entries(ws?.ws_events).map(([date, events]) => {
            return <div key={date}>
                <div key={date} className="mx-2 mt-4">
                    <TextSmall text={date} noSecondary={true} className=""/>
                </div>
                {events.map(event => {
                    return <Form.Group key={event.name} className="mx-3">
                        <FormCheck>
                            {multiEventWs && <FormCheck.Input onChange={onChange}
                                             checked={wsEventIds.includes(event.id)}
                                             className="border border-secondary mt-2"
                                             id={event.id} type="checkbox" name={event.name} value="1" required/>}
                            <FormCheck.Label htmlFor={event.name}>{event.name}</FormCheck.Label>
                            {findCurrentWsEvent(event.id)?.paid && <PaidBadge/>}
                        </FormCheck>
                    </Form.Group>
                })}
            </div>
        })}
        <div className="text-center d-grid gap-2 mt-5">
            <Button variant="primary" type="submit" onClick={onPaidClick}> {t('admin.add_payment')} </Button>
        </div>

        {multiEventWs && <div className="text-center d-grid gap-2 mt-4">
            <Button variant="white" type="submit" onClick={onModifyClick}> {t('admin.modify_ws_application')} </Button>
        </div>}

        <p className="small mt-4 text-center">
            <a data-method="delete" data-confirm={t('admin.confirm_delete_application')}
               href={`/admin/workshops/${ws?.id}/ws_applications/${applicationId}`}>
                <BsIcon classNames="me-1" icon={'trash'}/>
                {t('admin.delete_application')}
            </a>
        </p>
    </Modal>
}

export default WsApplicationModal;