import React from "react";
import BsIcon from "../bsIcon";
import {useNavigate} from "react-router-dom";

function BodyWrapper(props) {
    const navigate = useNavigate();

    return <div style={{marginBottom: "90px"}}>
        <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1 ps-2 text-primary" onClick={() => navigate(-1)}/>

        {props.children}
    </div>
}

export default BodyWrapper;