import React, {useState} from "react";
import { Form } from "react-bootstrap";
import BsCard from "../cards/bsCard";

function BasicForm(props) {
    const [validated, setValidated] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            if (props.keys) {
                const formData = props.keys.reduce((acc, key) => {
                    acc[key] = e.target[key].value;
                    return acc;
                }, {});

                props.onSubmit(formData)
            } else {
                props.onSubmit()
            }

        }

        setValidated(true);
    }

    if (props.title) {
        return <BsCard title={ props.title }>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                {props.children}
            </Form>
        </BsCard>

    } else {
        return <Form noValidate validated={validated} onSubmit={onSubmit}>
            {props.children}
        </Form>
    }
}

export default BasicForm;