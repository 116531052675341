import React from "react";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";

function Role(props) {
    const { t } = useTranslation();

    return <Form.Group className="my-3">
        <Form.Label className="mt-3">{ t('role') }</Form.Label>
        <Form.Control.Feedback className="mb-3" type="invalid" style={{ display: props.workshop?.full_for_followers ? 'block' : 'none' }}>{ t('errors.messages.only_leader_spots') }</Form.Control.Feedback>
        <Form.Control.Feedback className="mb-3" type="invalid" style={{ display: props.workshop?.full_for_leaders ? 'block' : 'none' }}>{ t('errors.messages.only_follower_spots') }</Form.Control.Feedback>
        <FormCheck className="mb-3">
            <FormCheck.Input onChange={props.handleInputChange}
                             checked={props.formData.role === "follower"}
                             disabled={props.workshop?.full_for_followers}
                             className="border border-secondary"
                             type="radio" id="follower" name="role" value="follower" required />
            <FormCheck.Label className="text-light" htmlFor="follower" style={{width: "100%"}}> {t('follower')} </FormCheck.Label>
        </FormCheck>
        <FormCheck className="mb-3">
            <FormCheck.Input onChange={props.handleInputChange}
                             checked={props.formData.role === "leader"}
                             disabled={props.workshop?.full_for_leaders}
                             className="border border-secondary"
                             type="radio" id="leader" name="role" value="leader" required />
            <FormCheck.Label className="text-light" htmlFor="leader" style={{width: "100%"}}> {t('leader')} </FormCheck.Label>
            <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>
        </FormCheck>
    </Form.Group>
}

export default Role;