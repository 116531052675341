import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Accordion, Badge, Button, Card} from "react-bootstrap";
import TextSmall from "../textSmall";
import BsCard from "./bsCard";
import WsEventCard from "./wsEventCard";
import TextPrimary from "../textPrimary";
import FullBadge from "../badges/fullBadge";
import AccordionWrapper from "../accordionWrapper";

function WorkshopCard({workshop, renderEvents, wrapped}) {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const body = (
        <>
            <TextSmall text={workshop.title} />
            <div className="d-flex flex-row justify-content-between align-items-center">
                <Card.Title>{workshop.name}</Card.Title>
                {renderEvents ? <Badge className="mb-2" bg="success" text="black">{t('applied')}</Badge> :
                    workshop.full && <FullBadge />}
            </div>

            <Card.Text>
                <TextPrimary br={true} text={workshop.teachers} />
                <TextSmall br={true} text={workshop.datetime} />
                <TextSmall br={true} text= {workshop.address} />
            </Card.Text>

            {renderEvents &&
                <AccordionWrapper arrow={false} button={<span className="accordion-button accordion-button-without-arrow">{t('events')}</span>}>
                    {Object.entries(renderEvents).map(([date, events]) => {
                        return <div key={date}>
                            {renderEvents.length > 1 && <TextSmall text={date} noSecondary={true} />}

                            {events.map((event) => (
                                <WsEventCard key={event.id}
                                             id={event.id}
                                             event={event}
                                             hideCheckbox={true} />
                            ))}
                        </div>;

                    })}
                </AccordionWrapper>
            }

            {(!renderEvents && !workshop.full) && <div className="d-grid mt-2">
                <Button variant="primary" onClick={() => navigate(`/workshops/${workshop.id}/apply`)}>
                    {t('interested')}
                </Button>
            </div>}
        </>
    );

    if(wrapped) {
        return <BsCard noStyle={true} className="mb-2 py-2">
                {body}
        </BsCard>
    } else {
        return body
    }
}

export default WorkshopCard;
