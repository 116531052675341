import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import Password from "./fields/password";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import BasicForm from "./basicForm";
import {performAjaxRequest} from "../../helpers";
import Name from "./fields/name";
import Gender from "./fields/gender";
import Submit from "./fields/submit";

function WorkshopApplyFirstStep(props) {
    const [emailValidationStatus, setEmailValidationStatus] = useState('');
    const { t } = useTranslation();
    const { id } = useParams();

    const validateEmail = e => {
        e.preventDefault()
        if (e.target.value === '') {
            setEmailValidationStatus('blank')
        } else {
            performAjaxRequest(
                "POST",
                '/users/validate_email.json',
                {email: e.target.value},
                {success: () => {setEmailValidationStatus('valid')},
                        error: (response) => {setEmailValidationStatus(response.responseJSON['error_code'])}})
        }
    }

    const emailBasedFields = () => {
        switch (emailValidationStatus) {
            case 'taken':
                return <>
                    <p className="small">{ t('already_have_account') }</p>
                    <Password value={props.formData.password}
                              handleInputChange={props.handleInputChange}
                              autoFocus={true} />
                </>
            default:
                return <>
                    <Name {...props} />
                    <Gender noJoker={true} {...props} />
                </>
        }
    }

    const onSubmit = (e) => {
        if (emailValidationStatus === 'taken') {
            performAjaxRequest(
                'POST',
                '/sign_in.json',
                { email: props.formData.email, password: props.formData.password, workshop_id: id },
                {props: props})
        } else if (emailValidationStatus !== 'valid') {
            e.stopPropagation();
        } else {
            props.setPage(props.page + 1)
        }
    }

    return <>
        <BasicForm>
            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control onBlur={validateEmail}
                              onChange={props.handleInputChange}
                              value={props.formData.email || ""}
                              isInvalid={emailValidationStatus !== 'valid' && emailValidationStatus !== ''}
                              isValid={emailValidationStatus === 'valid'}
                              type="email" name="email" size='sm' className="bg-light text-dark" autoFocus={true} />
                {emailValidationStatus !== 'taken' && <Form.Control.Feedback type="invalid"> { t(`errors.messages.${emailValidationStatus}`) } </Form.Control.Feedback>}
            </Form.Group>
        </BasicForm>
        <BasicForm onSubmit={onSubmit}>
            {emailBasedFields()}
            <Submit text={ emailValidationStatus === 'taken' ? t('login') : t('next') } />
            <div className="mt-3 small text-center">
                <Link to="/workshops">{ t('back') }</Link>
            </div>
        </BasicForm>
    </>
}

export default WorkshopApplyFirstStep;
