import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import WsEventCard from "../cards/wsEventCard";
import {useParams} from "react-router";
import BasicForm from "./basicForm";
import Submit from "./fields/submit";
import Back from "./fields/back";
import {performAjaxRequest} from "../../helpers";
import TextSmall from "../textSmall";

function WorkshopApplyThirdStep(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [showError, setShowError] = useState(false);
    const lastStep = props.currentUser && !props.customStepsExist

    const nextStep = () => {props.setPage(props.page + 1)}

    const onSubmit = () => {
        if (props.formData.ws_event_ids?.length > 0) {
            setShowError(false)
            if (lastStep) {
                performAjaxRequest(
                    'POST',
                    `/workshops/${id}/ws_applications.json`,
                    { applicant: props.formData },
                    {success: nextStep, props: props}
                )
            } else {
                nextStep()
            }
        } else {
            setShowError(true)
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }

    return <BasicForm onSubmit={onSubmit}>
        <Form.Group className="my-3">
            <Form.Label className="my-3">{ t('which_events') }</Form.Label>

            <Form.Control.Feedback type="invalid" style={{ display: showError ? 'block' : 'none' }}>
                { t("errors.messages.choose_at_least_one_ws_event") }
            </Form.Control.Feedback>
            {Object.entries(props.workshop?.ws_events).map(([date, events]) => (
                <div key={date}>
                    <TextSmall text={date} noSecondary={true} />

                    {events.filter(event => !event[`full_for_${props.formData.role}s`]).map((event) => (
                         <WsEventCard key={event.id}
                                      checkboxInputChange={props.eventCheckboxInputChange}
                                      id={event.id}
                                      event={event}
                                      checked={props.formData.ws_event_ids?.includes(event.id)} />
                        )
                    )}
                </div>
            ))}
        </Form.Group>

        <Submit text={ lastStep ? t('finish') : t('next')  } />
        <Back previousPage={props.page - 1} {...props} />
    </BasicForm>
}

export default WorkshopApplyThirdStep;