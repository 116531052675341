import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Modal as BootstrapModal } from 'bootstrap';

const Modal = forwardRef((props, ref) => {
    const modalRef = useRef();

    useImperativeHandle(ref, () => ({
        showModal() {
            const modal = new BootstrapModal(modalRef.current);
            modal.show();
        },
        hideModal() {
            const modal = new BootstrapModal(modalRef.current);
            modal.hide();
        }
    }));

    return (
        <div ref={modalRef} id={props.id} className="modal fade">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="modal-title">{props.title}</span>
                        <button
                            aria-label="Close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            type="button"
                        ></button>
                    </div>
                    <div className="modal-body">{props.children}</div>
                </div>
            </div>
        </div>
    );
});

export default Modal;
