import React from "react";
import {useTranslation} from "react-i18next";
import {Badge} from "react-bootstrap";

function FullBadge() {
    const { t } = useTranslation();

    return <Badge className="mb-2" bg="warning" text="black">
        {t('full')}
    </Badge>
}

export default FullBadge;