import React from 'react';

const CircleWithNumber = ({ color, number }) => {
    return (
        <div className="circle-container" style={{ position: "relative" }}>
            <div className="circle" style={{ backgroundColor: color,
                                             width: "2em",
                                             height: "2em",
                                             borderRadius: "25px",
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center"  }}>
                <span className="circle-number" style={{ color: "white", fontSize: "20px" }}>{number}</span>
            </div>
        </div>
    );
};

const Stepper = ({ numSteps, step, primaryColor }) => {
    const circles = [];

    for (let i = 1; i <= numSteps; i++) {
        const circleColor = i <= step + 1 ? primaryColor : "#adb5bd";
        circles.push(<CircleWithNumber key={i} color={circleColor} number={i} />);
        if (i < numSteps) {
            circles.push(
                <div
                    key={`line-${i}`}
                    className="line"
                    style={{
                        width: "30px",
                        height: "2px",
                        backgroundColor: i <= step ? primaryColor : "gray",
                    }}
                />
            );
        }
    }

    return <div className="connected-circles" style={{ display: "flex", alignItems: "center" }}>{circles}</div>;
};

export default Stepper;
