import React, {useEffect, useState} from "react";
import WorkshopApplyFirstStep from "../components/forms/workshopApplyFirstStep";
import WorkshopApplySecondStep from "../components/forms/workshopApplySecondStep";
import WorkshopApplyThirdStep from "../components/forms/workshopApplyThirdStep";
import WorkshopApplyFourthStep from "../components/forms/workshopApplyFourthStep";
import DataPolicyPage from "./dataPolicyPage";
import WorkshopApplyFinished from "../components/forms/workshopApplyFinished";
import {useParams} from "react-router";
import MultiStepForm from "../components/forms/multiStepForm";
import {performAjaxRequest} from "../helpers";
import WorkshopApplyCustomStep from "../components/forms/workshopApplyCustomStep";

const logged_out_steps = [WorkshopApplyFirstStep, WorkshopApplySecondStep, WorkshopApplyThirdStep, WorkshopApplyCustomStep, WorkshopApplyFourthStep, DataPolicyPage, WorkshopApplyFinished];
const logged_in_steps = [WorkshopApplySecondStep, WorkshopApplyThirdStep, WorkshopApplyCustomStep, WorkshopApplyFinished];

function WorkshopApplyPage(props) {
    const { id } = useParams();
    const [workshop, setWorkshop] = useState(null);
    const steps = props.currentUser ? logged_in_steps : logged_out_steps;
    const customStepsExist = workshop?.custom_fields?.length > 0
    const pages = customStepsExist ? steps : steps.filter(step => step !== WorkshopApplyCustomStep)
    const numSteps = props.currentUser ? pages.length - 1 :  pages.length - 2;

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/workshops/${id}.json`,
            null,
            {success: (response) => {setWorkshop(response)}, props: props})
    }, []);

    return <MultiStepForm pages={pages} numSteps={numSteps}
                          workshop={workshop}
                          customStepsExist={customStepsExist}
                          defaultFormData={{answers: {}}}
                          {...props}/>
}

export default WorkshopApplyPage;