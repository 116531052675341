import {useState, useEffect} from "react";
import "jquery.csrf";

export function duration(group) {
    const start = new Date(group.start_time);
    const end = new Date(group.end_time);

    const start_time = `${String(start.getHours()).padStart(2, '0')}:${String(start.getMinutes()).padStart(2, '0')}`
    const end_time = `${String(end.getHours()).padStart(2, '0')}:${String(end.getMinutes()).padStart(2, '0')}`

    return `${start_time} - ${end_time}`;
}

export function monthName(date) {
    let month = new Date(date).toLocaleString('hu', { month: 'long' } )
    return month.charAt(0).toUpperCase() + month.slice(1)
}

export function logout() {
    performAjaxRequest(
        'DELETE',
        '/logout',
        null,
        {success: function () {window.location.reload();}
    });
}

export function emptyFunction() {}

export function useStateWithInitial(initialValue) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return [value, setValue];
}

export function performAjaxRequest(
    method,
    url,
    data,
    options = {},
) {
    const { success, error, props  } = options;

    const handleErrorResponse = (response) => {
        if (typeof error === 'function') {
            error(response);
        } else if (props) {
            props.setFlash({ 'alert': response.responseJSON });
            props.setHideKeys(true);
        }
    };

    const handleSuccessResponse = (response) => {
        if (typeof success === 'function') {
            success(response);
        }
    };

    if (method === 'POST') {
        return $.post(url, data)
            .done(handleSuccessResponse)
            .fail(handleErrorResponse);
    } else {
        return $.ajax({
            type: method,
            url: url,
            data: data,
            dataType: data ? 'json' : null,
        }).done(handleSuccessResponse)
            .fail(handleErrorResponse);
    }
}

export function sortApplicationsByNameAndPaid(applications) {
    return applications?.sort((a, b) => {
        if ((a.paid ? 1 : 0) === (b.paid ? 1 : 0)) {
            return a.user.full_name.localeCompare(b.user.full_name)
        } else {
            return (a.paid ? 1 : 0) < (b.paid ? 1 : 0) ? -1 : 1
        }
    }) || []
}
