import React from "react";
import {v4 as uuid} from "uuid";


class FlashMessage extends React.Component {
    componentDidMount() {
        // $('.alert').delay(10000).slideUp(2000);
        this.removeSelf()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // $('.alert').show()
        this.removeSelf()
    }

    removeSelf() {
        // $('.alert').delay(10000).slideUp(2000);
        setTimeout(() => {  this.props.setFlash(null) }, 10000);
    }

    levelToClass(level) {
        switch(level) {
            case 'notice':
                return 'success'
            case 'alert':
                return 'danger'
        }
    }

    render () {
        const messages = []

        $.each(this.props.flash, (level, message) => {
            if (typeof message === 'string' || React.isValidElement(message)) {
                messages.push(<div key={uuid()} className={"alert alert-" + this.levelToClass(level)}> {message} </div>)
            } else {
                $.each(message, (key, value) => {
                    if (this.props.hideKeys) {
                        messages.push(<div key={uuid()} className={"alert alert-" + this.levelToClass(level)}> {value} </div>)
                    } else {
                        messages.push(<div key={uuid()} className={"alert alert-" + this.levelToClass(level)}> {key + ": " + value} </div>)
                    }
                })
            }
        })
        messages.push(<br key={uuid()} />)
        return messages
    }
}

export default FlashMessage;