import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import BodyWrapper from "../../components/containers/bodyWrapper";
import SubscriptionCard from "../../components/cards/subscriptionCard";

function SubscriptionsPage(props) {
    const { t } = useTranslation();

    const activeSubscriptions = useMemo(() => {
        return props.currentUser.subscriptions.filter(subscription => subscription.active === true)
    }, [props.currentUser.subscriptions])

    return <BodyWrapper>
        {activeSubscriptions.length > 0 ? activeSubscriptions.map((subscription) =>
            <SubscriptionCard key={subscription.id} subscription={subscription} />) :
            <div className="row">
                <div className="col">
                    <span> {t('no_active_subscription')} </span>
                </div>
            </div>}
    </BodyWrapper>
}

export default SubscriptionsPage;
