import React, {useEffect, useState} from "react";
import GroupCard from "../../components/cards/groupCard";
import {performAjaxRequest} from "../../helpers";
import {useTranslation} from "react-i18next";
import TextSmall from "../../components/textSmall";
import GroupsFilter from "../../components/forms/filters/groupsFilter";

function GroupsPage(props) {
    const { t } = useTranslation();

    const [groups, setGroups] = useState(null)
    const [filteredGroups, setFilteredGroups] = useState(null)
    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {
        performAjaxRequest('GET', '/groups.json', null, {success: response => {
            setGroups(response)
            setFilteredGroups(response)
        }})
    }, [props.groupIds])

    return <div style={{marginBottom: "90px"}}>
        <GroupsFilter groups={groups} showMessage={showMessage} setFilteredGroups={setFilteredGroups} noBackButton={props.noBackButton} />

        <div className="d-flex flex-row justify-content-end">
            <TextSmall text={t('join')}/>
        </div>

        {filteredGroups?.map(group => {
            return <GroupCard key={group.id}
                              group={group}
                              groupIds={props.groupIds}
                              setGroupIds={props.setGroupIds}
                              setShowMessage={setShowMessage}
                              user={props.currentUser}
                              {...props} />
        })}
    </div>
}

export default GroupsPage;
