import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Submit from "./fields/submit";
import TextSmall from "../textSmall";
import Name from "./fields/name";
import Email from "./fields/email";
import Gender from "./fields/gender";
import BasicForm from "./basicForm";

function RegistrationFirstStep(props) {
    const { t } = useTranslation();

    return <BasicForm onSubmit={() => props.setPage(1)}>
        <Name autoFocus={true} {...props} />
        <Email value={props.formData.email || ""} {...props} />
        <Gender {...props} />
        <Submit text={t('next')} />
        <div className="mt-5 text-center">
            <TextSmall br={true} noSecondary={true} text={ t('already_signed_up') } />
            <TextSmall text={<Link to="/">{ t('login_link') }</Link>} />
        </div>
    </BasicForm>
}

export default RegistrationFirstStep;
