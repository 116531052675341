import React from "react";
import RegistrationFirstStep from "../components/forms/registrationFirstStep";
import RegistrationSecondStep from "../components/forms/registrationSecondStep";
import DataPolicyPage from "./dataPolicyPage";
import MultiStepForm from "../components/forms/multiStepForm";

function RegistrationPage(props) {
    const pages = [RegistrationFirstStep, RegistrationSecondStep, DataPolicyPage];
    const defaultPreferences = {preferences: {event_notification: "1", subscription_notification: "1"}}

    return <MultiStepForm pages={pages} numSteps={2} defaultFormData={defaultPreferences} {...props}/>
}

export default RegistrationPage;