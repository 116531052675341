import React from "react";
import {useTranslation} from "react-i18next";
import Submit from "./fields/submit";
import {performAjaxRequest} from "../../helpers";
import BasicForm from "./basicForm";
import Password from "./fields/password";
import DataPolicy from "./fields/dataPolicy";
import Preferences from "./fields/preferences";
import Back from "./fields/back";

function RegistrationSecondStep(props) {
    const { t } = useTranslation();

    const handleError = response => {
        props.setFlash({ 'alert': response.responseJSON });
        props.setHideKeys(false);
    }

    const onSubmit = () => {
        performAjaxRequest("POST", '/users.json', { user: props.formData }, {error: handleError})
    }

    return <BasicForm onSubmit={onSubmit}>
        <Password autoFocus={true} value={props.formData?.password || "" } {...props} />
        <Preferences {...props} />
        <DataPolicy dataPolicyPage={2} {...props} />
        <Submit text={t('finish')} />
        <Back previousPage={0} {...props} />
    </BasicForm>
}

export default RegistrationSecondStep;