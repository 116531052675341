import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ContainerFluid from "../components/containers/container_fluid";
import EventCard from "../components/cards/eventCard";
import TextSmall from "../components/textSmall";

function EventsPage(props) {
    const [events, setEvents] = useState(null);
    const [applications, setApplications] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const [applicationsResponse, eventsResponse] = await Promise.all([
                $.get('/applications.json'),
                $.get('/events.json')
            ]);
            setApplications(applicationsResponse);
            setEvents(eventsResponse);
        };

        fetchData();
    }, []);

    const renderEventRow = (week, day, event, eventIndex) => {
        const application =  applications?.find(application => application.event_id === event.id)

        return <ContainerFluid key={event.id} dontWrap={true} className="my-2 ps-0">
            <div className="col-2 col-md-1 text-center">
                {eventIndex === 0 && <>
                    <TextSmall text={day.split(' ')[0]} noSecondary={true} br={true} />
                    <TextSmall text={day.split(' ')[1]} noSecondary={true} className="rounded-circle bg-dark p-1" />
                </>}
            </div>

            <EventCard event={event}
                       application_id={application?.id}
                       subscription_id={application?.subscription_id}
                       setApplications={setApplications}
                       {...props} />
        </ContainerFluid>
    };

    const renderWeek = (week, index) => (
        <div key={index}>
            <ContainerFluid key={index} className="justify-content-between">
                <TextSmall text={week} />
                {index === 0 && <TextSmall text={t('apply')} />}
            </ContainerFluid>

            {Object.keys(events[week]).map((day) =>
                Object.values(events[week][day]).map((event, eventIndex) =>
                    renderEventRow(week, day, event, eventIndex)
                )
            )}
        </div>
    );

    if(props.currentUser.groups.length === 0) {
        return <span>
                {t('no_groups')}
                <br/>
                <a href={`/users/${props.currentUser.id}/groups`}>{t('click_here')}</a> {t('to_join')}
        </span>
    } else if (events && Object.keys(events).length > 0) {
        return <div style={{ marginBottom: "90px" }}>
            {Object.keys(events).map((week, index) => renderWeek(week, index))}
        </div>
    } else {
        return <span> {t('no_events')} </span>
    }
}

export default EventsPage;