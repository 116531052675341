import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function Logo({currentUser, subdomain}) {
    const [logo, setLogo] = useState(null);
    const logoFileName = subdomain ? `logo_${subdomain}` : 'logo_ddance'

    useEffect(() => {
        import(`../../../../assets/images/${logoFileName}.jpg`).then((image) => setLogo(image.default))
            .catch(() => {
                import(`../../../../assets/images/logo_demo.jpg`)
                    .then((defaultImage) => setLogo(defaultImage.default));
            })
    }, [subdomain]);

    return <Link to={currentUser ? '/events' : '/'} className="navbar-brand">
        <img src={logo} alt="" width="60" height="60"/>
    </Link>
}

export default Logo;