import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import AdminWsEventCard from "../../components/cards/adminWsEventCard";
import {performAjaxRequest} from "../../helpers";
import BsIcon from "../../components/bsIcon";
import {useNavigate} from "react-router-dom";

function AdminWsEventsPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [wsEvents, setWsEvents] = useState(null);
    const [ws, setWs] = useState(null);

    useEffect(() => {
        performAjaxRequest(
            'GET',
                `/admin/workshops/${id}/ws_events.json`,
            null,
            {success: (response) => {
                setWsEvents(response["ws_events"]);
                setWs({
                    name: response["name"],
                    datetime: response["datetime"],
                })
            }}
        )
    }, []);

    return <div>
        <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1 text-primary" onClick={() => navigate(-1)}/>

        <div className="d-flex flex-column mb-3">
            <h4>{ws?.name}</h4>
            <span className="text-secondary">{ws?.datetime}</span>
        </div>

        {wsEvents?.map(wsEvent => {
            return <AdminWsEventCard key={wsEvent.id} event={wsEvent}/>
        })}
    </div>
}

export default AdminWsEventsPage;
