import React from "react";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Gender(props) {
    const { t } = useTranslation();

    return <Form.Group className="mb-3">
        <Form.Label className="mb-2">{ t('gender') }</Form.Label>
        <FormCheck isInvalid={props.formData.gender === ''}>
            <FormCheck.Input onChange={props.handleInputChange}
                             checked={props.formData.gender === "male"}
                             className="mb-2 border border-secondary" type="radio" id="male" name="gender" value="male"  required/>
            <FormCheck.Label className="text-light" htmlFor="male" style={{width: "100%"}}> {t('male')} </FormCheck.Label>
        </FormCheck>
        <FormCheck isInvalid={props.formData.gender === ''}>
            <FormCheck.Input onChange={props.handleInputChange}
                             checked={props.formData.gender === "female"}
                             className="mb-2 border border-secondary" type="radio" id="female" name="gender" value="female" required/>
            <FormCheck.Label className="text-light" htmlFor="female" style={{width: "100%"}}> {t('female')} </FormCheck.Label>
            <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>
        </FormCheck>
        {!props.noJoker && <FormCheck>
            <FormCheck.Input onChange={props.checkboxInputChange}
                             className="mb-2 border border-secondary" type="checkbox" id="joker" name="joker" value="1"/>
            <FormCheck.Label className="text-light" htmlFor="joker" style={{width: "100%"}}> {t('joker')} </FormCheck.Label>
        </FormCheck>}
    </Form.Group>
}

export default Gender;