import React from "react";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";

function Level(props) {
    const { t } = useTranslation();

    return <Form.Group className="my-3">
        <Form.Label className="my-3">{ t('level') }</Form.Label>

        {Object.keys(props.settings.levels).map((level, index) => {
            if(level !== "everyone") {
                return(<FormCheck key={index} className="mb-3">
                    <FormCheck.Input onChange={props.handleInputChange}
                                     checked={props.formData.level === level}
                                     className="border border-secondary"
                                     type="radio" id={level} name="level" value={level}  required/>
                    <FormCheck.Label className="text-light" htmlFor={level} style={{width: "100%"}}> {t(level)} </FormCheck.Label>
                    {index === Object.keys(props.settings.levels).length - 1 && <Form.Control.Feedback type="invalid"> { t('errors.messages.blank') } </Form.Control.Feedback>}
                </FormCheck>)
            }
        })}
    </Form.Group>
}

export default Level;