import React, {forwardRef} from "react";
import {Button} from "react-bootstrap";
import BsIcon from "../bsIcon";
import Modal from "./modal";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";
import TextPrimary from "../textPrimary";

const WsApplicationsEventsModal = forwardRef(({ wsEventId, wsApplications, applicationsEventsId, fetchApplications, ...props }, ref) => {
    const {t} = useTranslation();
    const currentApplication = wsApplications?.find(application => application.id === applicationsEventsId)

    const onPaidClick = () => {
        $('button.btn-close').click()
        performAjaxRequest(
            'PUT',
            `/admin/ws_events/${wsEventId}/ws_applications_events/${currentApplication.id}.json`,
            {ws_applications_event: {paid: true}},
            {
                success: () => {
                    fetchApplications();
                },
                error: () => {
                    alert(t('admin.ws_subscription_creation_failed'));
                }
            }
        )
    }

    return <Modal ref={ref} id="wsApplicationsEventsModal" title={t('admin.add_ws_subscription')}>
        <TextPrimary br={true} text={currentApplication?.user?.full_name}/>

        <div className="text-center d-grid gap-2 mt-5">
            <Button variant="primary" type="submit" onClick={onPaidClick}> {t('admin.add_payment')} </Button>
        </div>

        <p className="small mt-4 text-center">
            <a data-method="delete" data-confirm={t('admin.confirm_delete_application')}
               href={`/admin/ws_events/${wsEventId}/ws_applications_events/${currentApplication?.id}`}>
                <BsIcon classNames="me-1" icon={'trash'}/>
                {t('admin.delete_application')}
            </a>
        </p>
    </Modal>
}
);
export default WsApplicationsEventsModal;