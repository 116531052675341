import React from "react";
import TextPrimary from "../textPrimary";
import BsIcon from "../bsIcon";
import {monthName} from "../../helpers";
import TextSmall from "../textSmall";
import ContainerFluid from "../containers/container_fluid";
import {useTranslation} from "react-i18next";

function SubscriptionCard({subscription}) {
    const { t } = useTranslation();

    const icon = subscription.combined ? <BsIcon icon={subscription.plan.icon?.replace("bi-", "")}/> :
            <span> {subscription.plan.name} <BsIcon icon={subscription.plan.icon?.replace("bi-", "")}/> </span>

    return <ContainerFluid key={subscription.id}
                    className="bg-dark my-3 px-3 py-2"
                    shadow={true}
                    noFlex={true}
                    dontWrap={true}>
        <div className="d-flex flex-row justify-content-between border-bottom py-1">
            <TextPrimary text={subscription.name} />
            {icon}
        </div>

        <br/>

        <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
                            <span className="ms-auto mt-auto fw-bold small">
                                {!subscription.monthly && t('events_left') + " "}
                                {subscription.monthly ? monthName(subscription.end_date) : subscription.events_left}
                            </span>
            </div>

            <div className="d-flex flex-column ">
                <TextSmall text={t('validity')} className="text-end" />
                <TextSmall text={subscription.end_date} className="text-end" />
            </div>
        </div>
    </ContainerFluid>
}

export default SubscriptionCard;