import AdminGroupSection from "../../components/containers/adminGroupSection";
import React, {useEffect, useMemo, useState} from "react";
import {performAjaxRequest} from "../../helpers";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import AdminSubscriptionsSection from "../../components/containers/adminSubscriptionsSection";

function UserEditPage(props) {
    const { t } = useTranslation()
    const { id } = useParams();

    const [user, setUser] = useState(null)
    const [groupIds, setGroupIds] = useState([])
    const [allGroups, setAllGroups] = useState(null)
    const [reloadData, setReloadData] = useState(false);

    useEffect(() => {
        performAjaxRequest('GET', `/admin/users/${id}.json`, null, {
            success: response => {
                setUser(response);
            },
            error: error => reject(error)
        });
    }, [id, reloadData])

    useEffect(() => {
        if (user?.group_ids && user.group_ids.length > 0) {
            setGroupIds(user.group_ids);
        }
    }, [user]);

    const reloadUser = () => {
        setReloadData(prev => !prev);  // Toggle the reloadData state to trigger re-fetch
    };


    return <div className="col-md-5">
        <AdminGroupSection user={user} groups={allGroups} setAllGroups={setAllGroups} groupIds={groupIds} setGroupIds={setGroupIds} reloadUser={reloadUser} />
        <AdminSubscriptionsSection user={user} allGroups={allGroups} groupIds={groupIds} onSubscriptionSuccess={reloadUser} {...props} />
    </div>
}

export default UserEditPage;