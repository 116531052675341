import React from "react";
import {Link} from "react-router-dom";

function NavLink({to, text, active}) {
    return <li className="nav-item">
        <Link to={to} className={`nav-link ${active || location.pathname === to ? 'active' : ''}`}>
            {text}
        </Link>
    </li>
}

export default NavLink;