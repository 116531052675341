import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {InputGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {EyeFill, EyeSlashFill} from "react-bootstrap-icons";

function Password(props) {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    return <Form.Group className="mb-3">
            <Form.Label>{ t('password') }</Form.Label>
            <InputGroup>
                <Form.Control onChange={props.handleInputChange}
                              value={props.value}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              size='sm'
                              className="bg-light text-dark"
                              required
                              autoFocus={props.autoFocus || false}/>
                <InputGroup.Text onClick={() => setShowPassword(!showPassword)} className="text-dark">
                    {showPassword ? <EyeFill/> : <EyeSlashFill/>}
                </InputGroup.Text>
            </InputGroup>
        </Form.Group>
}

export default Password;
