import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {emptyFunction, performAjaxRequest} from "../../helpers";
import AccordionWrapper from "../accordionWrapper";
import GroupsFilter from "../forms/filters/groupsFilter";
import TextSmall from "../textSmall";
import GroupCard from "../cards/groupCard";

function AdminGroupSection({user, groups, setAllGroups, groupIds, setGroupIds}) {
    const { t } = useTranslation()

    const [filteredGroups, setFilteredGroups] = useState([])

    useEffect(() => {
        if (user) {
            performAjaxRequest('GET', `/admin/groups.json`, null, {
                success: response => {
                    setFilteredGroups(response);
                    setAllGroups(response)
                },
                error: error => reject(error)}
            )
        }
    }, [user, groupIds])

    return <AccordionWrapper arrow={true} rounded={true} button={<h4>{t('groups')}</h4>}>
        <GroupsFilter groups={groups} setFilteredGroups={setFilteredGroups} noBackButton={true} />

        <div className="d-flex flex-row justify-content-end">
            <TextSmall text={t('join')}/>
        </div>

        {filteredGroups?.map(group => {
            return <GroupCard key={group.id}
                              group={group}
                              groupIds={groupIds}
                              setGroupIds={setGroupIds}
                              setShowMessage={emptyFunction}
                              user={user} />
        })}
    </AccordionWrapper>
}

export default AdminGroupSection;