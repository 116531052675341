import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/themes/base/all.css';

const Autocomplete = ({url, selectCallback}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        $(inputRef.current).autocomplete({
            source: url,
            minLength: 2,
            create: function () {
                $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li>")
                        .append(
                            `<div style='line-height: 1;' class='py-2'>${item.full_name}<br><span class='text-secondary small'>${item.email}</span></div>`
                        )
                        .appendTo(ul);
                };
            },
            select: selectCallback,
        });

        return () => {
            $(inputRef.current).autocomplete('destroy');
        };
    }, [url, selectCallback]);

    return <div className="input-group">
        <input type="text" id="ws_applications_search" ref={inputRef} className='form-control'/>
        <div className="input-group-append">
            <i role="button" className="bi bi-search input-group-text fs-5"></i>
        </div>
    </div>
};

export default Autocomplete;
