import React from "react";
import { useTranslation } from 'react-i18next';
import Email from "../components/forms/fields/email";
import Submit from "../components/forms/fields/submit";
import {performAjaxRequest} from "../helpers";
import BasicForm from "../components/forms/basicForm";

function PasswordResetPage(props) {
    const { t } = useTranslation();

    const onSubmit = (formData) => {
        performAjaxRequest("POST", "/password_resets.json", formData, {props: props})
    }

    return <BasicForm keys={["email"]}
                      title={ t('password_reset') }
                      onSubmit={onSubmit} >
        <Email autoFocus={true} />
        <Submit text={ t('password_reset') } />
    </BasicForm>
}

export default PasswordResetPage;